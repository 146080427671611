import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useWindowDimensions } from "@bufox/react-utils/hooks"
import { compareResponsiveSizes } from "./utils"
/**
 * Dummy ad slot to use for testing purposes. It supports
 * Ad Manager ad unit configuration and responsive sizes.
 * In case of responsive sizes it chooses a random size for
 * each configured screen breakpoint.
 * @component
 *
 * @returns
 */
const DummySlot = ({ adUnitConfiguration }) => {
  /* sizes is an array that contains info about the sizes 
    (responsive or normal) of  the current ad slot.
    The sizes are sorted from the smallest to the largest
    screen width. For sizes with the same width, height
    will be taken into account.
    
     Structure:

     [0] --> {
       screen: {
         width: ...,
         height: ...
       },
       adUnitSize: {
          ...
          size: {
            ...
            width: ...
            height: ...
          } 
       }
     },
     [1] --> {
         screen: {
           width: ...,
           height: ...
         },
         adUnitSize: {
            ...
            size: {
              ...
              width: ...
              height: ...
            } 
         }
       },
      ...
     
     */
  const [sizes] = useState(() => {
    if (
      !adUnitConfiguration.adUnitSizes ||
      adUnitConfiguration.adUnitSizes.length === 0
    ) {
      return []
    }
    const output = []
    if (adUnitConfiguration.responsive) {
      // at first, we should sort the sizes.
      const sortedSizes = Object.values(adUnitConfiguration.responsive).sort(
        compareResponsiveSizes
      )
      for (const { screen, sizes: responsiveSizes } of sortedSizes) {
        const responsiveSizesArray = Object.values(responsiveSizes)
        output.push({
          screen: screen,
          adUnitSize:
            responsiveSizesArray[
              Math.floor(Math.random() * responsiveSizesArray.length)
            ],
        })
      }
    } else {
      // Just one random size
      return [
        adUnitConfiguration.adUnitSizes[
          Math.floor(Math.random() * adUnitConfiguration.adUnitSizes.length)
        ][1],
      ]
    }
    return output.reverse()
  })

  const { width: windowWidth, height: windowHeight } = useWindowDimensions()
  const calculateCurrentSize = () => {
    if (!sizes.length) {
      return null
    }
    if (adUnitConfiguration.responsive) {
      for (const sizeObject of sizes) {
        if (
          sizeObject.screen.width < windowWidth &&
          sizeObject.screen.height < windowHeight &&
          !sizeObject.screen.width !== 1 &&
          !sizeObject.screen.height !== 1
        ) {
          return sizeObject.adUnitSize
        }
      }
      return null
    }
    return sizes[0]
  }

  /* This is the current size, chosen between the compatible sizes in
   * the sizes array on the basis of the screen width / height.
   * This value should be initialized with the first size and
   * re-calculated at each window resize.
   */
  const [currentSize, setCurrentSize] = useState(calculateCurrentSize())

  /**
   * Let's recalculate the current size, on the basis of the screen
   * dimensions
   */
  useEffect(() => {
    setCurrentSize(calculateCurrentSize())
  }, [windowWidth, windowHeight])
  return (
    <div
      style={{
        width: currentSize ? currentSize.size.width : 0,
        height: currentSize ? currentSize.size.height : 0,
        background: "black",
        margin: "0 auto",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.8rem",
      }}
    >
      {currentSize
        ? `${currentSize.size.width}x${currentSize.size.height}`
        : null}
    </div>
  )
}

DummySlot.propTypes = {
  adUnitConfiguration: PropTypes.object,
}

export default DummySlot
